import React from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { ChecklistUploadContext } from 'views/pages/ChecklistUpload';
import { convertJST, downloadExcel, getHSEById } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

export const DataTable: React.FC = () => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    department_level_code,
    dept_name_lv2,
    dept_code_lv2,
    dept_name_lv3,
    dept_code_lv3,
    dept_name_lv4,
    dept_code_lv4,
    dept_name_lv5,
    dept_code_lv5,
    user_name,
    data,
    checkedInfo,
    setCheckedInfo,
    mapVerificationHist,
    inspectionMonth,
    selectedInspectionMonth,
  } = React.useContext(ChecklistUploadContext);

  const parPage = PAR_PAGE;
  const fiscal_year = parseInt(getHSEById('#fiscal_year')?.value);

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    setCheckedInfo({ key: -1 });
    setOffset(offset);
  };

  const handleCheckbox = (
    owner_user_type: number,
    inspectionMonthKey: number,
    inspectionMonthVal: string,
    category_code: number,
    category_name: string,
    subcategory_code: number,
    subcategory_name: string
  ) => {
    const key = `${inspectionMonthKey}:${category_code}:${subcategory_code}`;

    if (key === checkedInfo.key) {
      setCheckedInfo({
        key: -1,
      });
    } else {
      setCheckedInfo({
        key,
        owner_user_type,
        inspectionMonthKey,
        inspectionMonthVal,
        category_code,
        category_name,
        subcategory_code,
        subcategory_name,
      });
    }
  };

  const handleDownload = async (
    owner_user_type: number,
    inspection_month: number,
    inspectionMonthVal: string,
    category_code: number,
    category_name: string,
    subcategory_code: number,
    subcategory_name: string
  ) => {
    const res = await axios.post(
      '/api/checklist-upload/download',
      {
        fiscal_year,
        owner_user_type,
        pref_code,
        bank_code,
        inspection_month,
        inspectionMonthVal,
        category_code,
        category_name: category_name.substring(2),
        subcategory_code,
        subcategory_name: subcategory_name.substring(2),
        department_level_code,
        dept_name_lv2,
        dept_code_lv2,
        dept_name_lv3,
        dept_code_lv3,
        dept_name_lv4,
        dept_code_lv4,
        dept_name_lv5,
        dept_code_lv5,
        user_name,
      },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center' style={{ width: '10%' }}>
                選択
              </StyledTableCell>
              <StyledTableCell style={{ width: '15%' }}>対象月</StyledTableCell>
              <StyledTableCell style={{ width: '40%' }}>
                チェックリスト名
              </StyledTableCell>
              <StyledTableCell align='center'>差戻日時</StyledTableCell>
              <StyledTableCell align='center'>登録日時</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.slice(offset, offset + parPage).map((x) => (
              <StyledTableRow
                key={`${x.inspection_month}:${x.category_code}:${x.subcategory_code}`}
              >
                <StyledTableCell align='center'>
                  <input
                    type='checkbox'
                    checked={
                      checkedInfo.key ===
                      `${
                        Number(selectedInspectionMonth)
                          ? selectedInspectionMonth
                          : 1
                      }:${x.category_code}:${x.subcategory_code}`
                    }
                    className='target'
                    onChange={() => {
                      handleCheckbox(
                        x.owner_user_type,
                        Number(selectedInspectionMonth)
                          ? selectedInspectionMonth
                          : 1,
                        inspectionMonth[selectedInspectionMonth - 1]?.val ||
                          selectedInspectionMonth,
                        x.category_code,
                        x.category_name.substring(2),
                        x.subcategory_code,
                        x.subcategory_name.substring(2)
                      );
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  {inspectionMonth[selectedInspectionMonth - 1]?.val ||
                    selectedInspectionMonth}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(
                        x.owner_user_type,
                        Number(selectedInspectionMonth)
                          ? selectedInspectionMonth
                          : 1,
                        inspectionMonth[selectedInspectionMonth - 1]?.val ||
                          selectedInspectionMonth,
                        x.category_code,
                        x.category_name,
                        x.subcategory_code,
                        x.subcategory_name
                      );
                    }}
                  >
                    {`${x.category_name.substring(
                      2
                    )}_${x.subcategory_name.substring(2)}.xlsx`}
                  </span>
                </StyledTableCell>
                {mapVerificationHist.has(
                  [
                    fiscal_year,
                    pref_code,
                    bank_code,
                    x.inspection_month,
                    x.category_code,
                    x.subcategory_code,
                  ].join(':')
                ) ? (
                  <StyledTableCell align='center' style={{ color: 'red' }}>
                    {mapVerificationHist.get(
                      [
                        fiscal_year,
                        pref_code,
                        bank_code,
                        x.inspection_month,
                        x.category_code,
                        x.subcategory_code,
                      ].join(':')
                    )}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align='center'>{'-'}</StyledTableCell>
                )}
                <StyledTableCell align='center'>
                  {x.updated_at ? convertJST(x.updated_at) : '-'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data.length > PAR_PAGE && (
        <JICSPagination
          length={data.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};
