import React from 'react';
import axios from 'axios';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { downloadExcel } from 'utils/functions';
import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import { PAR_PAGE, USER_TYPE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC<{
  userType;
  checklist;
  checkedInfo;
  setCheckedInfo;
  checkedIds: number[];
  setCheckedIds;
  checkedInfos;
  setCheckedInfos;
}> = (props) => {
  const classes = useStyles();

  const {
    pref_code,
    bank_code,
    selectedFiscalYear,
    approvedIds,
    setApprovedIds,
    pivFiscalYear,
  } = React.useContext(InspectionSubCategoryContext);

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    setOffset(offset);
  };

  const handleCheckbox = (
    id: number,
    owner_user_type: number,
    pref_code: string,
    bank_code: string,
    category_code: number,
    category_name: string,
    subcategory_code: number,
    subcategory_name: string,
    comment: string,
    check_status: number
  ) => {
    if (props.userType !== USER_TYPE.PREF_ADMIN) {
      if (id === props.checkedInfo.id) {
        props.setCheckedInfo({
          id: -1,
          owner_user_type: -1,
        });

        props.setCheckedIds([]);
      } else {
        props.setCheckedInfo({
          id,
          owner_user_type,
          pref_code,
          bank_code,
          category_code,
          category_name: category_name.substring(2),
          org_category_name: category_name,
          subcategory_code,
          subcategory_name:
            owner_user_type < props.userType
              ? subcategory_name.substring(2)
              : subcategory_name,
          org_subcategory_name: subcategory_name,
          comment,
          check_status,
        });

        props.setCheckedIds([id]);
      }
    } else {
      const tmp = new Map(approvedIds);
      const key = `${category_code}:${subcategory_code}`;

      if (approvedIds.get(id)) {
        tmp.delete(id);
      } else {
        tmp.set(id, key);
      }

      setApprovedIds(tmp);

      if (props.checkedIds.includes(id)) {
        props.setCheckedIds(
          props.checkedIds.filter((checkedId) => {
            return checkedId !== id;
          })
        );

        props.setCheckedInfos(
          props.checkedInfos.filter((checkedInfo) => {
            return checkedInfo.id !== id;
          })
        );

        if (props.checkedInfos.length > 1) {
          props.setCheckedInfo(
            props.checkedInfos.filter((checkedInfo) => {
              return checkedInfo.id !== id;
            })[0]
          );
        }
      } else {
        props.setCheckedIds([...props.checkedIds, id]);
        const checkedInfo = {
          id,
          owner_user_type,
          pref_code,
          bank_code,
          category_code,
          category_name: category_name.substring(2),
          subcategory_code,
          subcategory_name:
            owner_user_type < props.userType
              ? subcategory_name.substring(2)
              : subcategory_name,
          org_subcategory_name: subcategory_name,
          comment,
          check_status,
        };

        if (props.checkedInfos.length === 0) {
          props.setCheckedInfo(checkedInfo);
        } else {
          props.setCheckedInfo(props.checkedInfos[0]);
        }

        props.setCheckedInfos([...props.checkedInfos, ...[checkedInfo]]);
      }
    }
  };

  const handleAllCheckbox = (checklist) => {
    const ids = [];

    const allCheckbox =
      document.querySelector<HTMLInputElement>('#allCheckbox');

    const tmp = new Map(approvedIds);

    if (allCheckbox.checked) {
      if (props.checkedIds.length === checklist.length) {
        props.setCheckedIds(ids);
        props.setCheckedInfos([]);
        props.setCheckedInfo({
          id: -1,
          owner_user_type: -1,
        });
      } else {
        const checkedInfoa = [];
        checklist.map((elm) => {
          if (elm.check_status === 0) {
            const id = elm.id;
            const bank_code = elm.bank_code;
            const category_code = elm.category_code;
            const category_name = elm.category_name;
            const subcategory_code = elm.subcategory_code;
            const subcategory_name = elm.subcategory_name;
            const comment = elm.comment;
            const check_status = elm.check_status;
            const checkedInfo = {
              id,
              bank_code,
              category_code,
              category_name,
              subcategory_code,
              subcategory_name,
              comment,
              check_status,
            };

            ids.push(id);
            checkedInfoa.push(checkedInfo);

            const key = `${category_code}:${subcategory_code}`;

            if (approvedIds.get(id)) {
              tmp.delete(id);
            } else {
              tmp.set(id, key);
            }
          }
        });

        props.setCheckedIds(ids);
        props.setCheckedInfos(checkedInfoa);
        props.setCheckedInfo({
          id: checklist.length !== 1 ? -1 : -2,
        });

        setApprovedIds(tmp);
      }
    } else {
      props.setCheckedIds([]);
      setApprovedIds(new Map());
    }
  };

  const handleDownload = async (
    owner_user_type,
    pref_code,
    bank_code,
    category_code,
    subcategory_code
  ) => {
    const res = await axios.post(
      '/api/template-checklist/download',
      {
        fiscal_year: selectedFiscalYear,
        owner_user_type,
        pref_code,
        bank_code,
        category_code,
        subcategory_code,
        user_type: props.userType,
      },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              {props.userType !== USER_TYPE.PREF_ADMIN && (
                <StyledTableCell align='center' style={{ width: '8%' }}>
                  選択
                </StyledTableCell>
              )}

              {props.userType === USER_TYPE.PREF_ADMIN && (
                <StyledTableCell align='center'>
                  <input
                    id='allCheckbox'
                    type='checkbox'
                    // checked={
                    //   props.checkedIds.length ===
                    //   props.checklist?.slice(offset, offset + parPage).length
                    // }
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, paddingLeft: 28 }}
                    onChange={() => {
                      handleAllCheckbox(props.checklist);
                    }}
                    disabled={selectedFiscalYear < pivFiscalYear}
                  />
                </StyledTableCell>
              )}
              {props.userType === USER_TYPE.PREF_ADMIN && (
                <StyledTableCell style={{ width: '8%' }}>承認</StyledTableCell>
              )}
              <StyledTableCell style={{ width: '18%' }}>
                チェックリスト区分
              </StyledTableCell>
              <StyledTableCell style={{ width: '18%' }}>
                チェックリスト名
              </StyledTableCell>
              <StyledTableCell style={{ width: '30%' }}>
                チェックリスト
              </StyledTableCell>
              <StyledTableCell>コメント</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.checklist?.slice(offset, offset + parPage).map((elm) => (
              <StyledTableRow key={elm.id}>
                <StyledTableCell align='center'>
                  <input
                    name='approved'
                    type='checkbox'
                    checked={
                      props.userType === USER_TYPE.PREF_ADMIN
                        ? props.checkedIds.includes(elm.id) &&
                          elm.check_status === 0
                        : props.checkedInfo.id === elm.id
                    }
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, paddingLeft: 28 }}
                    onChange={() => {
                      handleCheckbox(
                        elm.id,
                        elm.owner_user_type,
                        elm.pref_code,
                        elm.bank_code,
                        elm.category_code,
                        elm.category_name,
                        elm.subcategory_code,
                        elm.subcategory_name,
                        elm.comment,
                        elm.check_status
                      );
                    }}
                    disabled={
                      selectedFiscalYear < pivFiscalYear ||
                      (elm.check_status === 1 &&
                        props.userType !== USER_TYPE.SYSTEM_ADMIN)
                    }
                  />
                </StyledTableCell>
                {props.userType === USER_TYPE.PREF_ADMIN && (
                  <StyledTableCell>
                    {elm.check_status === 0 ? '未' : '済'}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {elm.category_name.substring(2)}
                </StyledTableCell>
                <StyledTableCell>
                  {elm.owner_user_type < props.userType
                    ? elm.subcategory_name.substring(2)
                    : elm.subcategory_name}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(
                        elm.owner_user_type,
                        pref_code,
                        bank_code,
                        elm.category_code,
                        elm.subcategory_code
                      );
                    }}
                  >
                    {`${elm.category_name.substring(
                      2
                    )}_${elm.subcategory_name.substring(2)}.xlsx`}
                  </span>
                </StyledTableCell>

                <StyledTableCell>{elm.comment}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {props.checklist.length > PAR_PAGE && (
        <JICSPagination
          length={props.checklist.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
