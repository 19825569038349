import React from 'react';
import axios from 'axios';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { useAtom } from 'jotai';

import { ChecklistDownloadContext } from 'views/pages/ChecklistDownload';
import { downloadExcel } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { offsetAtom } from 'jotai/atoms';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

export const DataTable: React.FC = () => {
  const classes = useStyles();

  const { pref_code, bank_code, user_type, data } = React.useContext(
    ChecklistDownloadContext
  );

  const parPage = PAR_PAGE;

  const [offset, setOffset] = useAtom(offsetAtom);

  const handlePagination = (offset: number) => {
    setOffset(offset);
  };

  const handleDownload = async (
    fiscal_year: number,
    owner_user_type: number,
    category_code: number,
    subcategory_code: number
  ) => {
    const res = await axios.post(
      '/api/checklist/download',
      {
        fiscal_year,
        user_type,
        owner_user_type,
        pref_code,
        bank_code,
        category_code,
        subcategory_code,
      },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow style={{ marginLeft: 100 }}>
              <StyledTableCell style={{ width: '22%', paddingLeft: 50 }}>
                チェックリスト区分
              </StyledTableCell>
              <StyledTableCell style={{ width: '20%' }}>
                チェックリスト名
              </StyledTableCell>
              <StyledTableCell style={{ width: '30%' }}>
                チェックリスト
              </StyledTableCell>
              <StyledTableCell>コメント</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.slice(offset, offset + parPage).map((x) => (
              <StyledTableRow
                key={`${x.inspection_month}:${x.category_code}:${x.subcategory_code}`}
              >
                <StyledTableCell style={{ width: '22%', paddingLeft: 50 }}>
                  {x.category_name.substring(2)}
                </StyledTableCell>
                <StyledTableCell>
                  {x.subcategory_name.substring(2)}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(
                        x.fiscal_year,
                        x.owner_user_type,
                        x.category_code,
                        x.subcategory_code
                      );
                    }}
                  >
                    {`${x.category_name.substring(
                      2
                    )}_${x.subcategory_name.substring(2)}.xlsx`}
                  </span>
                </StyledTableCell>
                <StyledTableCell>{x.comment}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data.length > PAR_PAGE && (
        <JICSPagination
          length={data.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};
