import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';

import { convertJST, downloadExcel } from 'utils/functions';
import { PAR_PAGE } from 'utils/constants';
import { JICSPagination } from 'views/components/JICSPagination';
import { HiddenColumnMasterContext } from 'views/pages/HiddenColumnMaster';
import { offsetAtom } from 'jotai/atoms';

import axios from 'axios';
import { useAtom } from 'jotai';

import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
} from '../../../../styles/DataTable';

const DataTable: React.FC = () => {
  const classes = useStyles();
  const {
    pref_code,
    bank_code,
    datas,
    checkedInfo,
    setCheckedInfo,
    setCategoryOption,
    selectedFiscalYearOption,
    pivFiscalYear,
  } = React.useContext(HiddenColumnMasterContext);

  const [offset, setOffset] = useAtom(offsetAtom);

  const handleCheckbox = (category_code, category_name) => {
    setCategoryOption([
      {
        key: category_code,
        val: category_name.substring(2),
      },
    ]);
    if (category_code === checkedInfo.category_code) {
      setCheckedInfo({ category_code: -1 });
    } else {
      setCheckedInfo({ category_code, category_name, bank_code });
    }
  };

  const parPage = PAR_PAGE;

  const handlePagination = (offset: number) => {
    setCheckedInfo({ category_code: -1 });
    setOffset(offset);
  };

  const handleDownload = async (
    owner_user_type,
    category_code,
    category_name
  ) => {
    const res = await axios.post(
      '/api/hidden-column/download',
      {
        fiscal_year: selectedFiscalYearOption,
        owner_user_type,
        pref_code,
        bank_code,
        category_code,
        category_name: category_name.substring(2),
      },
      { responseType: 'blob' }
    );

    downloadExcel(res);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ width: '80%', margin: 'auto' }}
      >
        <Table
          className={classes.table}
          aria-label='customized table'
          size='small'
        >
          <TableHead style={{ height: 50 }}>
            <TableRow>
              <StyledTableCell align='center'>選択</StyledTableCell>
              <StyledTableCell>チェックリスト区分</StyledTableCell>
              <StyledTableCell>非表示列設定テンプレート</StyledTableCell>
              <StyledTableCell>更新日時</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {datas?.slice(offset, offset + parPage).map((x) => (
              <StyledTableRow key={x.category_code}>
                <StyledTableCell align='center'>
                  <input
                    type='checkbox'
                    checked={checkedInfo.category_code === x.category_code}
                    className='target'
                    color='default'
                    style={{ width: 12, height: 12, padding: 28 }}
                    onChange={() => {
                      handleCheckbox(x.category_code, x.category_name);
                    }}
                    disabled={selectedFiscalYearOption < pivFiscalYear}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  {x.category_name.substring(2)}
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    className={classes.downloadable}
                    onClick={() => {
                      handleDownload(
                        x.owner_user_type,
                        x.category_code,
                        x.category_name
                      );
                    }}
                  >
                    {`非表示列設定（${x.category_name.substring(2)}）.xlsx`}
                  </span>
                </StyledTableCell>
                <StyledTableCell>
                  {x.updatedAt ? convertJST(x.updatedAt) : '-'}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {datas.length > PAR_PAGE && (
        <JICSPagination
          length={datas.length}
          parPage={parPage}
          offset={offset}
          handlePagination={handlePagination}
        />
      )}
    </>
  );
};

export default DataTable;
