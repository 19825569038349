import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import { DialogButtonCreate } from 'views/atoms/buttons/dialogs/Create';
import { DialogActionsCreate } from 'views/molecules/dialogs/actions/Create';
import { DialogTitleCreate } from 'views/molecules/dialogs/titles/Create';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const ApproveDialog: React.FC<{
  checkedIds;
  isDisabled: boolean;
  data;
  orgData;
}> = (props) => {
  const classes = useStyles();

  const {
    user_type,
    pref_code,
    selectedFiscalYear,
    setData,
    setOrgData,
    approvedIds,
    setCheckedIds,
    setCheckedInfo,
    setCheckedInfos,
    setApprovedInfo,
  } = React.useContext(InspectionSubCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleApproveChecklist = () => {
    Promise.all([
      axios.post('/api/inspection-subcategory/approve', {
        user_type,
        fiscal_year: selectedFiscalYear,
        pref_code,
        approvedInfo: JSON.stringify([...approvedIds]),
      }),
    ]).then(() => {
      setData(
        props.data.map((x) => {
          if ([...approvedIds.keys()].includes(x.id)) {
            x['check_status'] = 1;
          }
          return x;
        })
      );

      setOrgData(
        props.orgData.map((x) => {
          if ([...approvedIds.keys()].includes(x.id)) {
            x['check_status'] = 1;
          }
          return x;
        })
      );

      document
        .querySelectorAll<HTMLInputElement>("input[type='checkbox']")
        .forEach((x) => {
          x.checked = false;
        });

      setCheckedInfo({ id: -1 });
      setCheckedIds([]);
      setCheckedInfos([]);

      setApprovedInfo([...approvedIds]);

      handleClose();
    });
  };

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonCreate
        buttonLabel='チェックリスト承認'
        onClickActionHandler={handleOpen}
        isDisabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleCreate
          titleName='チェックリスト承認'
          onClickActionHandler={handleClose}
        />

        <Divider />

        <DialogContent>
          <span
            style={{
              color: 'red',
              fontSize: 13,
              textAlign: 'center',
            }}
          >
            ※&thinsp;承認されたチェックリストを未承認に戻すことはできません。
            <br />
            &emsp;&thinsp;ご注意ください。
          </span>
        </DialogContent>

        <Divider />

        <DialogActionsCreate
          buttonLabel='チェックリストを承認する'
          onClickActionHandler={handleApproveChecklist}
        />
      </Dialog>
    </Box>
  );
};

export default ApproveDialog;
