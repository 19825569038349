import React, { useState } from 'react';
import axios from 'axios';
import { Box, Dialog, DialogContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CHECKLIST_KBN_OPT, PROC_TYPE, USER_TYPE } from 'utils/constants';
import { InspectionSubCategoryContext } from 'views/pages/InspectionSubcategory';
import { InspectionSubCategoryForm } from 'views/components/forms/InspectionSubCategoryForm';
import { DialogButtonDelete } from 'views/atoms/buttons/dialogs/Delete';
import { DialogTitleDelete } from 'views/molecules/dialogs/titles/Delete';
import { DialogActionsDelete } from 'views/molecules/dialogs/actions/Delete';

const useStyles = makeStyles({
  paper: {
    borderRadius: 12,
    boxShadow: 'none',
  },
});

const DeleteDialog: React.FC<{
  checkedInfo;
  isDisabled: boolean;
}> = (props) => {
  const classes = useStyles();

  const {
    user_type,
    owner_user_type,
    pref_code,
    bank_code,
    eventHandlers,
    setData,
    setOrgData,
    setCheckedInfo,
    setRegCategory,
    setSearchOptions,
    setSelectedCategory,
    approvedInfo,
    selectedFiscalYear,
  } = React.useContext(InspectionSubCategoryContext);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setRegCategory([
      {
        key: props.checkedInfo.category_code,
        val: props.checkedInfo.category_name,
      },
    ]);

    eventHandlers.setSubcategoryName(props.checkedInfo.subcategory_name);
    eventHandlers.setComment(props.checkedInfo.comment);

    setIsDialogOpen(true);
  };

  const handleClose = () => {
    eventHandlers.setSubcategoryName('');
    eventHandlers.setComment('');

    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    axios
      .post('/api/inspection-subcategory/delete', {
        fiscal_year: selectedFiscalYear,
        owner_user_type,
        pref_code,
        bank_code,
        category_code: props.checkedInfo.category_code,
        subcategory_code: props.checkedInfo.subcategory_code,
      })
      .then((res) => {
        const tmpMap = new Map();

        const approvedMap = new Map();

        approvedInfo
          .filter((x) => {
            return x.pref_code === pref_code;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;
            approvedMap.set(key, 'dummy');
          });

        res.data
          .filter((x) => {
            return x.fiscal_year == selectedFiscalYear;
          })
          .map((x) => {
            const key = `${x.fiscal_year}:${x.category_code}:${x.subcategory_code}`;

            switch (parseInt(owner_user_type)) {
              case USER_TYPE.SYSTEM_ADMIN:
                if (!x.pref_code && !x.bank_code) {
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.PREF_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code)
                ) {
                  if (approvedMap.get(key)) {
                    x['check_status'] = 1;
                  } else {
                    x['check_status'] = 0;
                  }
                  tmpMap.set(key, x);
                }
                break;

              case USER_TYPE.JA_ADMIN:
                if (
                  (!x.pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && !x.bank_code) ||
                  (x.pref_code === pref_code && x.bank_code === bank_code)
                ) {
                  if (
                    x.owner_user_type === USER_TYPE.JA_ADMIN ||
                    approvedMap.get(key)
                  ) {
                    tmpMap.set(key, x);
                  }
                }
                break;
            }
          });

        const tmpArray = Array.from(tmpMap.values());
        setData(tmpArray);
        setOrgData(res.data);

        const opt = tmpArray
          .map((x) => {
            return {
              key: x.category_code,
              val: x.category_name.substring(2),
            };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          );

        setSearchOptions([...CHECKLIST_KBN_OPT, ...opt]);
        setSelectedCategory(0);

        eventHandlers.setSubcategoryName(props.checkedInfo.subcategory_name);
        eventHandlers.setComment(props.checkedInfo.comment);

        setCheckedInfo({ id: -1 });
        handleClose();
      });
  };

  const hdrStr =
    user_type === USER_TYPE.SYSTEM_ADMIN
      ? 'チェックリスト削除'
      : '独自チェックリスト削除';

  const btnStr =
    user_type === USER_TYPE.SYSTEM_ADMIN
      ? 'チェックリストを削除する'
      : '独自チェックリストを削除する';

  return (
    <Box component='span' style={{ marginRight: 16 }}>
      <DialogButtonDelete
        buttonLabel={hdrStr}
        onClickActionHandler={handleOpen}
        disabled={props.isDisabled}
      />

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth={'xs'}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitleDelete
          titleName={hdrStr}
          onClickActionHandler={handleClose}
        />

        <Divider />

        {(Number(owner_user_type) !== USER_TYPE.PREF_ADMIN ||
          (Number(owner_user_type) === USER_TYPE.PREF_ADMIN &&
            props.checkedInfo.check_status === 0)) && (
          <DialogContent>
            <InspectionSubCategoryForm
              isDisabled={{
                category: true,
                subcategoryName: true,
                comment: true,
              }}
              isHidden={{
                checklist: true,
                checkbox: true,
              }}
              procType={PROC_TYPE.DELETE}
            />
          </DialogContent>
        )}

        <Divider />

        <span
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 18,
            textAlign: 'center',
          }}
        >
          ※&thinsp;年度中にチェックリストの削除を行うと、これまでの検査結果が適正に
        </span>
        <span
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 3,
          }}
        >
          &thinsp;&thinsp;&thinsp;&thinsp;&emsp;&emsp;表示できなくなります。
        </span>
        <span
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 3,
            marginBottom: -10,
          }}
        >
          &thinsp;&thinsp;&thinsp;&thinsp;&emsp;&emsp;チェックリストの削除は年度管理マスタで年度更新後に行ってください。
        </span>

        {(Number(owner_user_type) !== USER_TYPE.PREF_ADMIN ||
          (Number(owner_user_type) === USER_TYPE.PREF_ADMIN &&
            props.checkedInfo.check_status === 0)) && (
          <DialogActionsDelete
            buttonLabel={btnStr}
            onClickActionHandler={handleDelete}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default DeleteDialog;
