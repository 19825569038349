import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@material-ui/core';

import axios from 'axios';
import { useSetAtom } from 'jotai';

import { DataTable } from 'views/components/tables/ChecklistDownload/DataTable';
import { CHECKLIST_KBN_OPT, USER_TYPE } from 'utils/constants';

import { TplPage } from 'views/templates/TplPage';
import { offsetAtom } from 'jotai/atoms';

export const ChecklistDownloadContext = React.createContext(
  {} as {
    pref_code: string;
    bank_code: string;
    user_type: number;
    data;
  }
);

export const ChecklistDownload: React.FC = () => {
  const user = JSON.parse(decodeURIComponent(sessionStorage.getItem('user')));

  const { pref_code, bank_code, user_type } = user;

  const [categoryOption, setCategoryOption] = React.useState(CHECKLIST_KBN_OPT);
  const [currCategory, setCurrCategory] = React.useState<number>(0);

  const [data, setData] = React.useState([]);
  const [orgData, setOrgData] = React.useState([]);

  const setOffset = useSetAtom(offsetAtom);
  const [regFiscalYearOption, setRegFiscalYearOption] = React.useState([]);
  const [currFiscalYear, setCurrFiscalYear] = React.useState(0);

  const [prefOption, setPrefOption] = React.useState([{ key: '0', val: '－' }]);
  const [jaOption, setJaOption] = React.useState([{ key: '0', val: '－' }]);

  const [currPref, setCurrPref] = React.useState('0');
  const [currJA, setCurrJA] = React.useState('0');

  const [approved, setApproved] = React.useState([]);

  const [orgPref, setOrgPref] = React.useState([]);

  const [publishedFiscalYear, setPublishedFiscalYear] = React.useState([]);

  const handleChangeC = (e) => {
    const category_code = parseInt(e.target.value);

    setCurrCategory(category_code);

    const tmp = new Map();
    const wk = new Map();

    approved
      .filter((x) => {
        return x.fiscal_year === currFiscalYear && x.pref_code === currPref;
      })
      .map((x) => {
        const key = `${x.category_code}:${x.subcategory_code}`;
        wk.set(key, 'dummy');
      });

    orgData
      .filter((x) => {
        return (
          x.fiscal_year === currFiscalYear &&
          (!x.pref_code || x.pref_code === currPref) &&
          (!x.bank_code || x.bank_code === currJA)
        );
      })
      .map((x) => {
        const key = `${x.category_code}:${x.subcategory_code}`;
        if (
          currJA == '0' ||
          wk.get(key) ||
          x.owner_user_type === USER_TYPE.JA_ADMIN
        ) {
          tmp.set(key, x);
        }
      });

    setData(Array.from(tmp.values()));

    if (category_code === 0) {
      setData(Array.from(tmp.values()));
    } else {
      setData(
        Array.from(tmp.values()).filter((x) => {
          return x.category_code === category_code;
        })
      );
    }

    setOffset(0);
  };

  React.useEffect(() => {
    Promise.all([
      axios.get('/api/login-fiscal-year', {
        params: {
          pref_code,
          bank_code,
          user_type,
        },
      }),
      axios.get('/api/approved_checklist'),
      axios.get('/api/checklist-info'),
      axios.get('/api/published-fiscal-year'),
    ]).then((res) => {
      const loginFiscalYear = res[0].data;
      const approvedChecklist = res[1].data;
      const checklistInfo = res[2].data;
      const tmpPublishedFiscalYear = res[3].data;

      let curr_year;

      switch (parseInt(user_type)) {
        case USER_TYPE.SYSTEM_ADMIN:
        case USER_TYPE.ALL_PREF_ADMIN: {
          const fiscalYearMap = loginFiscalYear
            .map((x) => {
              return { key: x.fiscal_year, val: `${x.fiscal_year} 年度` };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex((x) => x.key === elm.key) === idx
            );

          curr_year = fiscalYearMap[0].key;

          setCurrFiscalYear(curr_year);
          setRegFiscalYearOption(fiscalYearMap);

          const prefMap = loginFiscalYear
            .filter((x) => {
              return x.fiscal_year === curr_year;
            })
            .filter((x) => {
              return x.pref_code && x.pref_name;
            })
            .map((x) => {
              return { key: x.pref_code, val: x.pref_name };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex((x) => x.key === elm.key) === idx
            );

          setPrefOption([...prefOption, ...prefMap]);

          setJaOption([...jaOption]);

          break;
        }

        case USER_TYPE.PREF_ADMIN: {
          const fiscalYearMap = loginFiscalYear
            .filter((x) => {
              return x.pref_code === pref_code;
            })
            .map((x) => {
              return { key: x.fiscal_year, val: `${x.fiscal_year} 年度` };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex((x) => x.key === elm.key) === idx
            );

          curr_year = fiscalYearMap[0].key;

          setCurrFiscalYear(curr_year);
          setRegFiscalYearOption(fiscalYearMap);

          const prefMap = loginFiscalYear
            .filter((x) => {
              return x.fiscal_year === curr_year && x.pref_code === pref_code;
            })
            .filter((x) => {
              return x.pref_code && x.pref_name;
            })
            .map((x) => {
              return { key: x.pref_code, val: x.pref_name };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex((x) => x.key === elm.key) === idx
            );

          setPrefOption([...prefMap]);

          const jaMap = loginFiscalYear
            .filter((x) => {
              return (
                x.fiscal_year === curr_year &&
                x.pref_code === pref_code &&
                x.bank_code !== null
              );
            })
            .map((x) => {
              return { key: x.bank_code, val: x.ja_nickname };
            });

          if (jaMap.length === 0) {
            setJaOption([...jaOption]);
          } else {
            setJaOption([...jaOption, ...jaMap]);
          }

          break;
        }
      }

      const tmp = new Map();

      switch (parseInt(user_type)) {
        case USER_TYPE.SYSTEM_ADMIN:
        case USER_TYPE.ALL_PREF_ADMIN: {
          checklistInfo
            .filter((x) => {
              return (
                x.fiscal_year === curr_year && !x.pref_code && !x.bank_code
              );
            })
            .map((x) => {
              const key = `${x.category_code}:${x.subcategory_code}`;
              tmp.set(key, x);
            });

          setData(Array.from(tmp.values()));
          setOrgData(checklistInfo);

          break;
        }

        case USER_TYPE.PREF_ADMIN: {
          checklistInfo
            .filter((x) => {
              return (
                x.fiscal_year === curr_year &&
                (!x.pref_code || x.pref_code === pref_code) &&
                !x.bank_code
              );
            })
            .filter((x) => {
              return (
                (x.owner_user_type === USER_TYPE.SYSTEM_ADMIN &&
                  tmpPublishedFiscalYear.includes(x.fiscal_year)) ||
                x.owner_user_type === USER_TYPE.PREF_ADMIN ||
                x.owner_user_type === USER_TYPE.JA_ADMIN
              );
            })
            .map((x) => {
              const key = `${x.category_code}:${x.subcategory_code}`;
              tmp.set(key, x);
            });

          setData(Array.from(tmp.values()));

          setOrgData(
            checklistInfo.filter((x) => {
              return (
                (x.owner_user_type === USER_TYPE.SYSTEM_ADMIN &&
                  tmpPublishedFiscalYear.includes(x.fiscal_year)) ||
                x.owner_user_type === USER_TYPE.PREF_ADMIN ||
                x.owner_user_type === USER_TYPE.JA_ADMIN
              );
            })
          );

          setCurrPref(pref_code);

          break;
        }
      }

      setOrgPref(loginFiscalYear);
      setApproved(approvedChecklist);

      setCategoryOption([
        ...categoryOption,
        ...Array.from(tmp.values())
          .map((x) => {
            return {
              key: x.category_code,
              val: x.category_name.substring(2),
            };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          ),
      ]);

      setPublishedFiscalYear(tmpPublishedFiscalYear);
    });

    setOffset(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFiscalYear = (e) => {
    const fiscalYear = parseInt(e.target.value);

    setCurrFiscalYear(fiscalYear);

    const tmp = new Map();

    switch (parseInt(user_type)) {
      case USER_TYPE.SYSTEM_ADMIN:
      case USER_TYPE.ALL_PREF_ADMIN: {
        orgData
          .filter((x) => {
            return x.fiscal_year === fiscalYear && !x.pref_code && !x.bank_code;
          })
          .map((x) => {
            const key = `${x.category_code}:${x.subcategory_code}`;
            tmp.set(key, x);
          });

        setData(Array.from(tmp.values()));

        const prefMap = orgPref
          .filter((x) => {
            return x.fiscal_year === fiscalYear;
          })
          .filter((x) => {
            return x.pref_code && x.pref_name;
          })
          .map((x) => {
            return { key: x.pref_code, val: x.pref_name };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          );

        setPrefOption([...[{ key: '0', val: '－' }], ...prefMap]);

        setJaOption([{ key: '0', val: '－' }]);

        setCurrPref('0');

        break;
      }

      case USER_TYPE.PREF_ADMIN: {
        orgData
          .filter((x) => {
            return (
              x.fiscal_year === fiscalYear &&
              (!x.pref_code || x.pref_code === pref_code) &&
              !x.bank_code
            );
          })
          .map((x) => {
            const key = `${x.category_code}:${x.subcategory_code}`;
            tmp.set(key, x);
          });

        setData(Array.from(tmp.values()));

        const jaMap = orgPref
          .filter((x) => {
            return (
              x.fiscal_year === fiscalYear &&
              x.pref_code === pref_code &&
              x.bank_code !== null
            );
          })
          .map((x) => {
            return { key: x.bank_code, val: x.ja_nickname };
          });

        if (jaMap.length === 0) {
          setJaOption([...[{ key: '0', val: '－' }]]);
        } else {
          setJaOption([...[{ key: '0', val: '－' }], ...jaMap]);
        }

        setCurrPref(pref_code);

        break;
      }
    }

    setCategoryOption([
      ...CHECKLIST_KBN_OPT,
      ...Array.from(tmp.values())
        .map((x) => {
          return {
            key: x.category_code,
            val: x.category_name.substring(2),
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
        ),
    ]);

    setCurrJA('0');
    setCurrCategory(0);

    setOffset(0);
  };

  const handleChangePref = (e) => {
    const pref_code = e.target.value;

    setCurrPref(pref_code);

    if (pref_code == 0) {
      const tmp = orgData.filter((x) => {
        return x.fiscal_year === currFiscalYear && !x.pref_code && !x.bank_code;
      });

      setData(tmp);

      setJaOption([{ key: '0', val: '－' }]);

      setCategoryOption([
        ...CHECKLIST_KBN_OPT,
        ...tmp
          .map((x) => {
            return {
              key: x.category_code,
              val: x.category_name.substring(2),
            };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          ),
      ]);

      setCurrJA('0');
    } else {
      const tmpData = orgPref.filter((x) => {
        return (
          x.fiscal_year === currFiscalYear &&
          x.pref_code === pref_code &&
          x.bank_code === null
        );
      });

      const tmp = new Map();

      if (tmpData.length === 0) {
        orgData
          .filter((x) => {
            return (
              x.fiscal_year === currFiscalYear && x.pref_code === pref_code
            );
          })
          .map((x) => {
            const key = `${x.category_code}:${x.subcategory_code}`;
            tmp.set(key, x);
          });

        setData(Array.from(tmp.values()));

        setJaOption([
          ...orgPref
            .filter((x) => {
              return (
                x.fiscal_year === currFiscalYear &&
                x.pref_code === pref_code &&
                x.bank_code
              );
            })
            .map((x) => {
              return { key: x.bank_code, val: x.ja_nickname };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex((x) => x.key === elm.key) === idx
            ),
        ]);

        const tmpBankCode = orgPref.filter((x) => {
          return (
            x.fiscal_year === currFiscalYear &&
            x.pref_code === pref_code &&
            x.bank_code
          );
        })[0].bank_code;

        setCurrJA(tmpBankCode);
      } else {
        orgData
          .filter((x) => {
            return (
              x.fiscal_year === currFiscalYear &&
              (!x.pref_code || x.pref_code === pref_code) &&
              !x.bank_code
            );
          })
          .filter((x) => {
            return (
              (x.ower_user_type === USER_TYPE.SYSTEM_ADMIN &&
                publishedFiscalYear.includes(x.fiscal_year)) ||
              x.owner_user_type === USER_TYPE.PREF_ADMIN
            );
          })
          .map((x) => {
            const key = `${x.category_code}:${x.subcategory_code}`;
            tmp.set(key, x);
          });

        setData(Array.from(tmp.values()));

        setJaOption([
          ...[{ key: 0, val: '－' }],
          ...orgPref
            .filter((x) => {
              return (
                x.fiscal_year === currFiscalYear &&
                x.pref_code === pref_code &&
                x.bank_code
              );
            })
            .map((x) => {
              return { key: x.bank_code, val: x.ja_nickname };
            })
            .filter(
              (elm, idx, self) =>
                self.findIndex((x) => x.key === elm.key) === idx
            ),
        ]);

        setCurrJA('0');
      }

      setCategoryOption([
        ...CHECKLIST_KBN_OPT,
        ...Array.from(tmp.values())
          .map((x) => {
            return {
              key: x.category_code,
              val: x.category_name.substring(2),
            };
          })
          .filter(
            (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
          ),
      ]);
    }

    setCurrCategory(0);

    setOffset(0);
  };

  const handleChangeJA = (e) => {
    const bank_code = e.target.value;

    setCurrJA(bank_code);

    const tmp = new Map();

    if (bank_code == 0) {
      orgData
        .filter((x) => {
          return x.fiscal_year === currFiscalYear;
        })
        .filter((x) => {
          return (!x.pref_code || x.pref_code == currPref) && !x.bank_code;
        })
        .filter((x) => {
          return (
            (x.owner_user_type === USER_TYPE.SYSTEM_ADMIN &&
              publishedFiscalYear.includes(x.fiscal_year)) ||
            x.owner_user_type === USER_TYPE.PREF_ADMIN
          );
        })
        .map((x) => {
          const key = `${x.category_code}:${x.subcategory_code}`;
          tmp.set(key, x);
        });

      setData(Array.from(tmp.values()));
    } else {
      const wk = new Map();

      approved
        .filter((x) => {
          return x.fiscal_year === currFiscalYear && x.pref_code === currPref;
        })
        .map((x) => {
          const key = `${x.category_code}:${x.subcategory_code}`;
          wk.set(key, 'dummy');
        });

      orgData
        .filter((x) => {
          return x.fiscal_year === currFiscalYear;
        })
        .filter((x) => {
          return (
            !x.pref_code ||
            (x.pref_code === currPref && !x.bank_code) ||
            (x.pref_code === currPref && x.bank_code === bank_code)
          );
        })
        .map((x) => {
          const key = `${x.category_code}:${x.subcategory_code}`;
          if (wk.get(key) || x.owner_user_type === USER_TYPE.JA_ADMIN) {
            tmp.set(key, x);
          }
        });

      setData(Array.from(tmp.values()));
    }

    setCategoryOption([
      ...CHECKLIST_KBN_OPT,
      ...Array.from(tmp.values())
        .map((x) => {
          return {
            key: x.category_code,
            val: x.category_name.substring(2),
          };
        })
        .filter(
          (elm, idx, self) => self.findIndex((x) => x.key === elm.key) === idx
        ),
    ]);

    setCurrCategory(0);

    setOffset(0);
  };

  return (
    <TplPage
      content={
        <main>
          <Box
            style={{
              paddingTop: '40px',
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            チェックリストの出力
          </Box>

          <div
            style={{
              fontSize: '18px',
              paddingTop: '25px',
              textAlign: 'center',
            }}
          >
            <FormControl
              variant='outlined'
              style={{ width: '200px', marginRight: 8 }}
            >
              <InputLabel shrink>検査年度を選択</InputLabel>
              <Select
                native
                value={currFiscalYear}
                onChange={handleChangeFiscalYear}
                label='検査年度を選択'
              >
                {regFiscalYearOption &&
                  regFiscalYearOption.map((x, i) => (
                    <option value={x.key} key={i}>
                      {x.val}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl
              variant='outlined'
              style={{ width: '200px', marginRight: 8 }}
            >
              <InputLabel shrink>都道府県を選択</InputLabel>
              <Select
                native
                value={currPref}
                onChange={handleChangePref}
                label='都道府県を選択'
              >
                {prefOption &&
                  prefOption.map((x, i) => (
                    <option value={x.key} key={i}>
                      {x.val}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl
              variant='outlined'
              style={{ width: '200px', marginRight: 8 }}
            >
              <InputLabel shrink>JAを選択</InputLabel>
              <Select
                native
                value={currJA}
                onChange={handleChangeJA}
                label='JAを選択'
              >
                {jaOption &&
                  jaOption.map((x, i) => (
                    <option value={x.key} key={i}>
                      {x.val}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl variant='outlined' style={{ width: '200px' }}>
              <InputLabel>チェックリスト区分を選択</InputLabel>
              <Select
                native
                id='category'
                onChange={handleChangeC}
                label='チェックリスト区分を選択'
                value={currCategory}
              >
                {categoryOption.map((x) => (
                  <option key={x.key} value={x.key}>
                    {x.val}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <ChecklistDownloadContext.Provider
            value={{
              pref_code: currPref,
              bank_code: currJA,
              user_type,
              data,
            }}
          >
            <Box mt={3} mb={3}></Box>

            <DataTable />
          </ChecklistDownloadContext.Provider>
        </main>
      }
    />
  );
};
